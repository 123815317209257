<template>
  <div class="relative">
    <div class="upload-example">
      <div :class="cropperClass"
           class="fixed overflow-y-scroll left-0 top-0 z-50 bg-black right-0 bottom-0 bg-opacity-25 justify-center items-start"
           style="z-index: 50000">

        <div class="w-full lg:max-w-4xl p-4 pb-0 bg-white relative shadow mt-14 rounded-lg">
          <cropper
              ref="cropper"
              class="upload-example-cropper"
              :src="image.src"
              :stencil-props="stencilProps"
              :default-position="{
                left: 20,
                top: 20
              }"
              :stencil-default-size="{width: 100, height: 100}"
          />
          <div class="bg-white sticky bottom-0 py-4 right-4 left-4 z-50 flex justify-between">
            <btn @click.prevent="crop" variant="success" class="">Done</btn>
            <btn variant="warning" @click.prevent="cropperClass='hidden'" class="">Close</btn>
          </div>
        </div>

      </div>

      <div class="button-wrapper sticky bg-white bottom-0 overflow-hidden inline-block">
        <label class="button opacity-90  bottom-4 left-0 right-0 text-center z-2 border bg-green-600" @click="$refs.file.click()">
          <btn variant="info">Change</btn>
          <input type="file" ref="file" @change="loadImage($event)" class="absolute " style="left: 100%" accept="image/*">
        </label>
      </div>

    </div>
  </div>
</template>

<script>

import { Cropper } from 'vue-advanced-cropper'
import Btn from "../../../../components/btn";

// This function is used to detect the actual image type,
function getMimeType(file, fallback = null) {
  const byteArray = (new Uint8Array(file)).subarray(0, 4);
  let header = '';
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }
  switch (header) {
    case "89504e47":
      return "image/png";
    case "47494638":
      return "image/gif";
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg";
    default:
      return fallback;
  }
}

const stencilProps = {
  aspectRatio: 1 / 1,
};

export default {
  name: "ImageCropper",
  emits:['update:modelValue','crop'],
  components: {
    Btn,
    Cropper,
  },
  data() {
    return {
      image: {
        src: null,
        type: "image/png"
      },
      cropperClass: 'hidden',
      preview:null,
      stencilProps
    };
  },
  methods: {
    crop() {
      const { canvas } = this.$refs.cropper.getResult();
      const preview = canvas.toDataURL(this.image.type);

      canvas.toBlob(
         (blob) =>{
          this.$emit( 'crop', {blob, preview} );
          this.$emit( 'update:modelValue', blob );
        },'image/png'
      );

      //this.reset();
    },
    reset() {
      this.image = {
        src: null,
        type: null
      };

      this.cropperClass = 'hidden';
    },
    loadImage(event) {
      // Reference to the DOM input element
      const { files } = event.target;
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src)
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0]);

        // 3. The steps below are designated to determine a file mime type to use it during the
        // getting of a cropped image from the canvas. You can replace it them by the following string,
        // but the type will be derived from the extension and it can lead to an incorrect result:
        //
        // this.image = {
        //    src: blob;
        //    type: files[0].type
        // }

        // Create a new FileReader to read this image binary data
        const reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.image" refers to the image of Vue component
          this.image = {
            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
            src: blob,
            // Determine the image type to preserve it during the extracting the image from canvas:
            type: getMimeType(e.target.result, files[0].type),
          };
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsArrayBuffer(files[0]);
      }

      this.cropperClass = 'flex';
    },
  },
  destroyed() {
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    if (this.image.src) {
      URL.revokeObjectURL(this.image.src)
    }
  },
  mounted() {
    this.cropperClass = 'hidden';

  }
};

</script>

<style scoped>

</style>