<template>
  <div>
      <div>
        <div>

          <profile-avatar :src="profile_data.avatar" @update="handle_profile_picture_update"></profile-avatar>

          <profile-item @updated="dataUpdated" name="name" label="Name" :val="profile_data.name"/>

          <profile-item @updated="dataUpdated" name="phone" label="Phone" :val="profile_data.phone" :editable="false"/>

          <profile-item @updated="dataUpdated" name="guardian-phone" label="Guardian Phone"
                        :val="profile_data.guardian_phone"
                        :editable="!profile_data.guardian_phone" />

          <profile-item @updated="dataUpdated" name="email" label="Email"
                        :val="profile_data.email"/>

          <profile-item @updated="dataUpdated" name="gender" label="Gender"
                        :val="profile_data.gender"/>

          <profile-item @updated="dataUpdated" name="date_of_birth" label="Date of Birth"
                        :val="profile_data.date_of_birth"/>

          <area-and-address
              @updated="dataUpdated"
              :division-id="profile_data.division_id"
              :district-id="profile_data.district_id"
              :area-id="profile_data.area_id"
              :address="profile_data.address"
              :area="profile_data.area"
          ></area-and-address>
        </div>
      </div>
  </div>
</template>

<script>
import Container from "../../layouts/container";
import ProfileItem from "./components/profile-item";
import AreaAndAddress from "./components/area-and-address";
import ProfileAvatar from "./components/profile-avatar";
export default {
  name: "basic-data",
  data: {
    loading: true,
    profile_data: { }
  },
  components: {ProfileAvatar, AreaAndAddress, ProfileItem, Container},
  methods: {
    dataUpdated( data ){

      this.profile_data = data.student;
      this.$store.commit( 'student/setUser', data.student );
    },
    handle_profile_picture_update({data}){
      console.log({D:data}, data.url)
      this.profile_data.avatar = data.url;
    }
  },
  created() {
    this.$store.dispatch( 'student/profile', {
      success: ( {data} )=> {
        this.profile_data = data.student;
      }
    }).finally( () => this.$emit( 'loaded' ) );
  }
};
</script>

<style scoped>
</style>
