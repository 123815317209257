<template>
  <div class="border-b border-gray-200 flex items-center py-4">

    <template v-if="editing">
      <form class="border mt-4 p-4 bg-gray-50 w-full" @submit.prevent="update">
        <status-message :status-code="statusCode" :message="message" :timeout="3000"></status-message>

        <div class="text-center mt-3 font-semibold text-base mb-3">Area and Address</div>

        <div class="grid grid-cols-2 gap-4">

          <div>
            <input-dropdown :items="divisions" v-model="data.division_id" @change="loadDivisions" searchable>
              <template v-slot:label>Division</template>
            </input-dropdown>
          </div>

          <div>
            <input-dropdown :items="districts" v-model="data.district_id" searchable>
              <template v-slot:label>District</template>
            </input-dropdown>
          </div>

          <div>
            <input-dropdown :items="areas" v-model="data.area_id" searchable>
              <template v-slot:label>Thana/Upazila/Area</template>
            </input-dropdown>
          </div>

          <div>
            <div class="bg-white px-3 pt-2 pb-0.5 border-2 rounded focus:border-blue-200" tabindex="1">
              <label class="font-semibold">Address</label>
              <textarea class="w-full h-8 resize-none focus:outline-none" v-model="data.address" placeholder="Type your address"></textarea>
            </div>
          </div>

        </div>

        <div class="flex justify-center w-full mt-4">
          <btn variant="success" class="mr-3">Done</btn>
          <btn @click.prevent="editing=false" variant="danger-outline">Cancel</btn>
        </div>

      </form>
    </template>

    <template v-else>


      <div class="flex-grow flex flex-col">
        <status-message :status-code="statusCode" :message="message" :timeout="3000"></status-message>
        <div>
          <div>
            <div class="font-bold">Area</div>
            <div>{{ area }}</div>
            <div class="italic">{{ address }}</div>
          </div>
        </div>
      </div>

      <div>
        <btn v-if="!editing" variant="info-outline" @click="enableEditing">Edit</btn>
      </div>

    </template>


  </div>

</template>

<script>
import InputDropdown from "../../../../components/input/input-dropdown";
import StatusMessage from "../../../../components/status-message";
import Btn from "../../../../components/btn";
export default {
  name: "area-and-address",
  components : {Btn, StatusMessage, InputDropdown},
  props: {
    areaId: '',
    districtId: '',
    divisionId: '',
    address: '',
    area: ''
  },
  data(){
    return {
      data: {
        division_id: this.divisionId,
        district_id: this.districtId ,
        area_id: this.areaId,
        address: this.address,
      },
      divisions: [],
      message: '',
      statusCode: 200,
      editing: false,
    }
  },
  watch: {
    divisionId( id ){
      this.data.division_id = id;
    },
    districtId( id ){
      this.data.district_id = id;
    },
    areaId( id ){
      this.data.area_id = id;
    },
    area( area ){ },
    address( address ){
      this.data.address = address;
    },
  },
  computed:{
    getDivision(){
      return this.divisions;
    },
    districts(){
      const selectedDivision = ( this.divisions.filter( div => div.id === this.data.division_id ) )[0];
      return selectedDivision && Array.isArray( selectedDivision.districts ) ? selectedDivision.districts:[];
    },
    areas(){
      const selectedDistrict = ( this.districts.filter( div => div.id === this.data.district_id ) )[0];
      return selectedDistrict && Array.isArray( selectedDistrict.areas ) ? selectedDistrict.areas:[];
    }
  },
  methods: {
    enableEditing(){
      this.editing = true;
    }
    ,
    update(){

      this.$store.dispatch('student/updateAreaAndAddress', {
        data: { area_id: this.data.area_id, address: this.data.address },
        success: ({data} ) => {
          this.profile_data = data.student;
          this.message = data.message;
          this.statusCode = 200;
          this.editing = false;
          this.$emit( "updated", data )
        },
        error: ( response ) => {
          this.message = response.data.message;
          this.statusCode = response.status;
        }
      })


    }
  },
  created() {

    this.$store.dispatch( 'area/loadDivisionsIfNotLoaded' )
        .then( ( divisions ) => {
          this.divisions = divisions;
        })
        .catch( console.error )
  }
}
</script>

<style scoped>

</style>