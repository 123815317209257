<template>
  <div class="flex flex-col items-center relative">


    <div class="font-semibold">Profile Picture</div>


    <span class="items-center rounded-full text-center gap-4 relative h-28 w-28 border bg-gray-400 overflow-hidden">

      <div class="inline-block  text-center h-full">
        <div class="rounded shadow  h-full w-full inline-block">
          <img :src="imageSrc" alt="" class="h-full" style="display: block; min-width: auto" :class="editing ? 'opacity-20':''"/>
        </div>
      </div>

    </span>

    <image-cropper class="mt-2" @crop="onImageCrop" v-model="image" v-if="!editing"></image-cropper>

    <div v-if="editing" class="w-full md:w-96 mt-2 mb-10 lg:mb-0">

      <div class="text-center mb-2 text-red-400" v-if="error">{{error}}</div>

      <template v-else>
        <div class="text-center mb-2" v-if="updating">Updating...</div>
        <div class="text-center mb-2 text-red-500" v-else>Click save to update <br>or <br>cancel to discard</div>
      </template>

      <div class="gap-2 flex w-full justify-between">
        <btn @click.prevent="submitProfileAvatar" variant="success">Save</btn>
        <btn @click.prevent="onCancel" variant="danger-outline">Cancel</btn>
      </div>
    </div>
  </div>
</template>

<script>

import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import ImageCropper from "./Cropper";
import axios from "../../../../store/axios";

export default {
  name: "profile-avatar",
  components: {ImageCropper, Cropper},
  props: {
    src:String
  },
  data(){
    return {
      image:'',
      imageSrc:'',
      editing: false,
      updating: false,
      error: null,
    }
  },
  methods:{
    change(){

    },
    onImageCrop({preview}){
      this.imageSrc = preview;
      this.editing = true;
    },
    onCancel(){
      this.editing=false;
      this.imageSrc = this.src;
    },
    async submitProfileAvatar(){

      const formData = new FormData();

      formData.append('file', this.image );

      const headers = {'Content-Type': `multipart/form-data`};

      this.updating = true;

      try{
        const {data} = await axios( ).post('/student/profile/avatar', formData, {headers} );
        console.log( { data });

        this.$emit('update', {data});

      } catch (err) {
        this.error = err;
        console.log( { err });
      }

      this.updating = false;
      this.editing = false;

    }
  },
  mounted() {
    this.error = null;
    this.updating = false;
    this.editing = false;

  },
  watch: {
    src(src){
      this.imageSrc = src;
    }
  }
}



</script>

<style scoped>

</style>